.hotel-info {
  margin-top: 50px;
  background-color: #eceaea;
}
.hotel-info .info-box{
 min-height: 500px;
}
.hotel-info .info-box,.hotel-info .book-form {
  border: 1px solid #cfbcbc;
  margin: 10px;
  padding: 10px;
  border-radius: 6px;
}
// .card{
//     width:100% !important;
// }
// @media (max-width:767px) {
//     .bottom-card .table{
//         min-width: 600px;
//         overflow-x: scroll;
//     }
// }
.bottom-card{
  width:100%;
}
.info-box ul{
    padding:0
}
.info-box ul h4 {
  padding-bottom: 10px;
  border-bottom: 1px solid #999;
}
ul {
}

#rates {
  display: flex !important;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  // z-index: 1000;
}
// #rates .table,
// .t {

//   // min-width: 600px;
//   width:100%;
//   margin: 3px;
//   overflow-x: scroll;
//   // border:1px solid black;
// }
.main-table {
  // min-width: 460px;
  width:100%;
  margin: 3px;
  // border:1px solid black;
}
.trips-tables {
  // overflow: hidden;
  min-width: 300px;
  overflow: scroll;
}
.tab-pane {
  text-align: start;
}
.tab-pane ul {
  list-style: disc;
}
.t h6,
.main-table h6 {
  background-color: orange;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  font-size: 22px;
}
.trip-heading {
  background-color: green !important;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
}
.star {
  font-size: 16px;
}

// .tabs-card{
//   width:100%
// }
