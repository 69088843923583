.outbound-rate-table-body tbody tr{
    // border-bottom: 1px solid #191919;
}
.outbound-rate-table-body th{
        vertical-align: middle;
    text-align: center;
}
.outbound-rate-table-body td{
    vertical-align: middle;
    text-align: center;
    width:200px;
    // border:1px solid #bebebe;
}
.outbound-rate-table-body th{
    // vertical-align: middle;
    width:200px;
    // text-align: center;
    // border:1px solid #bebebe;
}

.outbound-rate-table-body th:nth-of-type(2){
// height: 50px;
}

.ql-align-center strong{
    color:#000
}


