// .domestics{
//     background-image:url("../../images/travelimg1.jpg");
//     background-position: center;
//     // object-fit: cover;
//       background-attachment: fixed;
//     background-repeat: no-repeat;
//     background-size: cover;

// }
.main-domestic {
}
// .main-domestic .main-form {
//   width: 450px !important;
//   width: 48% !important;
// }
.main-domestic {
}
.domestic-section {
  // margin-top: 10px;
}
.col {
  height: 100%;
  border: 1px solid black;
}
.domestic-hotels .row .col .card {
  margin-bottom: 10px;
}
.filter {
  margin-bottom: 20px;
  text-align: center;
}
.rating-filter ul {
  padding: 0;
}
.rating-filter button {
  margin: 5px;
}

// .rates-stars {
//   cursor: pointer;
//   transition: all 0.3s ease-in-out;
// }
// .rates-stars:hover {
//   border-radius: 5px;
//   background-color: #989898;
//   transform: scale(1.1);
// }


.shadow-md {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}
.navbar{
  // border-radius: 5px;
  // border-radius-bottom-right:5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.sidebar{
    width: 280px;
    min-height: 100vh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: #fff;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 1;
    transition: 0.5s;
}
.sidebar.active{
    left: 0;
}
.sd-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}
.sidebar-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}
.sidebar-overlay.active{
    opacity: 1;
    visibility: visible;
}
.sd-body{
    padding: 15px;
    max-height: calc(100vh - 67px);
    overflow-x: hidden;
}
.sd-body ul{
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
}
.sd-body ul li{
    list-style: none;
    margin-bottom: 8px;
}
.sd-body ul li .sd-link{
    display: inline-block;
    width: 100%;
    padding: 10px 16px;
    color: #475f7b;
    background-color: #e5e8ec;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}


@media (min-width:768px) {
    .Banner{
    // background-image:url("../../images/domestic/Beach-1.jpg") !important;
    background-position:center 70px !important;
    background-size: 100% 90% !important;
  }
}
.btn-close{
  z-index: 1000;
}

.react-reveal {
  opacity: 1 !important;
}

@media (max-width:767px) {
  // .Banner{
  //     background-size: contain;
  //     height:50vh;
  // }

  .domestics{
      margin-top: 40vh !important;
  }
}
