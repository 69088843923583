
#hajj-card{
    position: relative;
}

#hajj-btn {
  position: absolute;
  bottom: 20px;
  left: 20px 
}
