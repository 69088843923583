// @import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&family=Cairo:wght@300;400;500;600;700;900&family=Castoro+Titling&family=IBM+Plex+Sans+Arabic:wght@200;300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@300;400;500;600;700;800;900&family=Roboto:wght@300;400;500;700;900&family=Ubuntu:wght@300;400;500;700&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&family=Chivo:wght@300;400;500;600;700;800&family=Nunito:wght@200;300;400;500;600;700&family=Pacifico&family=Playpen+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@300;400;500;600;700;800&family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&family=Chivo:wght@300;400;500;600;700;800&family=Pacifico&family=Playpen+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@300;400;500;600;700;800&family=Ubuntu:wght@300;400;500;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Poppins:wght@300;400;500;600;700;800&family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&family=Work+Sans:wght@200;300;400;500;600;700;800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&family=Pacifico&family=Playpen+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@300;400;500;600;700;800&family=Ubuntu:wght@300;400;500;700&display=swap');
// html{
//   font-size: 20px;
// }
:root {
  --black-color: #000;
  --white-color: #fff;
  --primary-color: #fc4c03;
  --light-blue-color: #00b5e2;
  --second-blue-color: #00cfb4;
  --dark-blue-color: #0857c3;
  --red-color: #e4002b;
  --yellow-color: #ffcd00;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p{
  margin-bottom: 0px !important;
}
body {
  font-family: 'Work Sans', sans-serif;
  // font-family: "Open Sans", sans-serif;
  // font-family: 'Chivo', sans-serif;
  // font-family: 'Nunito', sans-serif;
  // font-family: 'Pacifico', cursive;
  // font-family: ;
  // font-family: 'Caveat', cursive;
  // background-color: #e2e2e2;
  // background-color: rgb(255, 255, 255);
  margin-top: 67px;
  // position: relative;
  // height: 5000px;
  // back;
  box-sizing: border-box;
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:30px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}
.container {
  // overflow: hidden;
}
.carousel {
  color: orange;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: #fff;
}
.navbar-light .navbar-nav .nav-link {
  color: #000;
}
// .row + div{
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
// }
.hotel-card {
  width: 90% !important;
  // width:330px;
  padding: 0;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 0.3s ease-in-out;
}

.hotel-card:hover {
  transform: scale(1.02);
}
.hotel-card .card-image {
  overflow: hidden;
  // z-index: 1;
}
.hotel-card .card-image .card-img-top {
  width: 100%;
  transition: all 0.3s ease;
}
.hotel-card .card-image .card-img-top:hover {
  transform: scale(1.3) rotate(7deg);
}
.hotel-info{
  background-color: #fff !important;
}
.book-form {
  margin:0 !important;
  padding: 20px;
  background-color: #eceaea;
}
@media (max-width:992px) {
  .book-rates{
    flex-direction: column-reverse;
  }
}

#rates .main-table{
  overflow-x: scroll;
}

.main-heading {
//  font-family: 'Pacifico', cursive;
  font-size: 30px;
  // font-weight: bold;
  color: #000;
  // border: 3px solid var(--dark-blue-color);
  border-radius: 5px;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 10px;
  // font-style: italic;
  // font-weight: bold;
  position: relative;
}
// .main-heading::before{
//   content:"";
//   position: absolute;
//   left:-10px;
//   transform: translateY(50%);
//   width:15px;
//   height: 15px;
//   border-radius: 50%;
//   background-color: var(--primary-color);
// }
// .main-heading::after{
//   content:"";
//   position: absolute;
//   right:-10px;
//   transform: translateY(50%);
//   width:15px;
//   height: 15px;
//   border-radius: 50%;
//   background-color: var(--primary-color);
// }

.star {
  // color: var(--red-color);
  color: #fff;
  color: gold;
}


// card style 

.hotel-card {
  margin: 10px;
  min-height: 370px;
 border:2px solid var(--yellow-color)
}
.card-image{
  height: 160px;
}
.card-image img{
  width: 350px;
    height: 160px;
    object-fit: cover;
}

button{
  // background-color: var(--yellow-color) !important;
  // color:var(--red-color) !important
  // width:140px
}

.card-desc{
    height: 40px !important;
    overflow: hidden;
  
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; 
}

// book form

.contact-us {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.contact-us form {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.contact-us form .left-form,
.contact-us form .right-form {
  width: 45%;
}
@media (max-width: 991px) {
  .contact-us form .left-form,
  .contact-us form .right-form {
    width: 100%;
  }
}
.contact-us input[type="text"],
.contact-us textarea {
  // min-width:400px;
  width: 100%;
}
.contact-us .right-form textarea {
  height: 100px;
}

.form-check {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.side-filter-col {
  padding: 15px;
  background-color: var(--red-color);
  color: #fff;
}
.side-filter-col .form-check:hover label{
  cursor: pointer;
  // background-color: var(--second-blue-color);
  // background-color: none !important;
  // border: 1px solid var(--yellow-color);
  // border-radius: 4px;
  color:#000
}
.side-filter-col .form-check-label {
  padding: 3px;
  margin-left: 10px;
  // padding-left:5px
}

.form-check .form-check-input {
  margin-left: -1em;
  margin-top: 0;
}
// .side-filter-col .form-check {
//   background-color: var(--dark-blue-color);
//   // padding-left:5px
// }
.carousel-inner{
  min-height: 90% !important;
}
.carousel .carousel-inner .carousel-item .carousel-caption {
  color: black;
}
.carousel .carousel-inner .carousel-item img {
  width: 100%;
  height: 550px;
}
// .carousel .carousel-inner .carousel-item img {
//   width: 100%;
//   height: 300px;
// }

.card{
  position: relative;
}
#card-btn{
position: absolute;
bottom: 20px;
right:20px;
background-color:var(--primary-color);
color: var(--yellow-color);
border:none

}

#card-btn:hover{
  color:var(--primary-color);
background-color: var(--yellow-color);
}
#card-btn:hover a{
  color:#000
}

#book-btn{
// background-color:var(--primary-color);
background-color: inherit !important;
border:2px solid var(--primary-color) !important;
color: var(--primary-color) !important;
font-weight: bold;
border:none;
// float: right;
width: 100%;


}

#book-btn:hover{

  color:#fff !important;
background-color: var(--primary-color) !important;
}




.table-rates{
  width:900px;
  // width:fit-content
}
th,td{
  text-align: center !important;
}
.t h6, .main-table h6 {
  width:800px
}
// hot offer 

.hot-offer-box {

  position: relative;
  // border: 1px solid #bbb;
  // background: #eee;
  float: left;
  // margin: 20px;
}
.ribbon {
  position: absolute;
  right: 16px;
  top: 0px;
  z-index: 1;
  overflow: hidden;
  width: 93px;
  height: 93px;
  text-align: right;
}
.ribbon span {
  font-size: 0.8rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 32px;
  transform: rotate(45deg);
  width: 120px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 17px; // change this, if no border
  right: -29px; // change this, if no border
}

.ribbon span::before {
   content: '';
   position: absolute; 
   left: 0px; top: 100%;
   z-index: -1;
   border-left: 3px solid #79A70A;
   border-right: 3px solid transparent;
   border-bottom: 3px solid transparent;
   border-top: 3px solid #79A70A;
}
.ribbon span::after {
   content: '';
   position: absolute; 
   right: 0%; top: 100%;
   z-index: -1;
   border-right: 3px solid #79A70A;
   border-left: 3px solid transparent;
   border-bottom: 3px solid transparent;
   border-top: 3px solid #79A70A;
}

.red span {
  background: linear-gradient(#f70505 0%, #8f0808 100%);
}
.red span::before {
  border-left-color: #8f0808;
  border-top-color: #8f0808;
}
.red span::after {
  border-right-color: #8f0808;
  border-top-color: #8f0808;
}

.blue span {
  background: linear-gradient(#2989d8 0%, #1e5799 100%);
}
.blue span::before {
  border-left-color: #1e5799;
  border-top-color: #1e5799;
}
.blue span::after {
  border-right-color: #1e5799;
  border-top-color: #1e5799;
}

.foo {
  clear: both;
}

.bar {
  content: "";
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #79a70a;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}

.baz {
  font-size: 1rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 2em;
  transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 100px;
  left: 1000px;
}



.css-176wh8e-MuiCircularProgress-circle {
  stroke: var(--primary-color) !important;
}


.tab-pane *{
font-size: 14px !important;
font-weight: normal !important;
}

.footer{
  z-index: 999 !important;
}


@media (max-width:767px) {
  .react-reveal{
    margin-top: 35vh !important;
  }
  .react-reveal.container-fluid{
    margin-top: 35vh !important;
  }

  .trans-carousel{
    height: 250px !important;
  }
  .trans-carousel img{
    height: 250px !important;
  }


}
@media (min-width:991px) {
  .custom-opacity{
    opacity: 0.9;
  }
}


.chrome{
  margin-bottom: 50px;
}
.fly-cont{
background-color: #fff;

}

@media(min-width:991px){
  .fly-cont{
    width:75%;
    margin-top: -110px;
  }
}
.fly-cont input,.form-select{
border-color: #999;
}

@media (max-height:767px) {
  .main-home-slider{
max-height: 300px !important;
  }
}

.carousel-control-prev-icon,.carousel-control-next-icon,.carousel-indicators{
  display:none !important;
}
.most-popular{
  min-height: 40vh !important;
}

.content-links li {
margin-bottom: 0 !important;
}

@media (max-width:767px) {
  .fly-title{
font-size: 18px;
  }

  .new-title{
    font-size: 35px !important;
  }
  .new-cont{
    width:100% !important
  }
  // .con-big{
  //   width:100% !important;
  //   text-align: center;
  // }
  // .con-box{
    
  //   flex-direction: column;
  // }
}

.dropdown:hover>.dropdown-menu{
  display: block;
}

.rates-stars:hover{
cursor: pointer;
}


@media(min-width:767px){
  .popup1 .modal-dialog{
    position: absolute !important;
    right:15% !important;
    }
    .popup2 .modal-dialog{
    position: absolute !important;
    left:15% !important;
    }
}
@media(min-width:1600px){
  .popup1 .modal-dialog{
    position: absolute !important;
    right:25% !important;
    }
    .popup2 .modal-dialog{
    position: absolute !important;
    left:25% !important;
    }
}

// @media(max-width:767px){
//   .popup1 .modal-dialog{
//     position: absolute !important;
//     right:15% !important;
//     }
//     .popup2 .modal-dialog{
//     position: absolute !important;
//     left:15% !important;
//     }
// }