@import "../../index.scss";

.hotel-card {
  margin: 10px;
  min-height: 500px;
}
.card-image{
  height: 250px;
}
.card-image img{
  width: 350px;
    height: 250px;
    object-fit: cover;
}

button{
  // background-color: var(--yellow-color) !important;
  // color:var(--red-color) !important
  // width:140px
}

.card-desc{
    height: 40px !important;
    overflow: hidden;
  
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; 
}