@import "../../../src/index.scss";
ul {
  list-style: none;
}

.footer {
  color: #fff;
  min-height: 300px;
  // margin-top: 40px;
  // background-color: var(--black-color);
  background-image: linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.7)) ,url("../../images/Footer-3.jpg") !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 10px;
  // left: 0;
  // bottom: 0;
  // z-index: 0;
  // width:100%;
  
}
// @media (max-width:767px) {
//     .footer .row{
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: center;
//     }
// }
.footer ul {
  width: 180px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  list-style: none;
}
.footer ul li a svg {
  margin-top: 2px;
  font-size: 20px;
}
.face-icon {
  color: #fff;
}
.insta-icon {
  color: #fff;
}
.youtube-icon {
  color: #fff;
}

.content-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content-links li {
  // text-align: center;
  margin-bottom: 5px;
  padding: 3px;
  width: 100%;
  color:var(--primary-color);
  // border:2px solid var(--primary-color);
  // padding:5px 10px;
  // border-radius: 5px;
}
.content-links li a{
  font-size: 16px;
}
.content-links li:hover {
  // border:1px solid #fff;
  // padding:5px 10px;
  // border-radius: 5px;
  // background-color: #fff;
}
.content-links li:hover a {
  font-weight: 600;
  color: var(--primary-color);

}

.footer input[type="text"],
.footer input[type="email"] {
  width: 300px;
}


@media(min-width:991px){

  .f-t{
    height:50px
  }
}



