@import "../../index.scss";

.hotel {
  margin: 10px;
}

button{
  // background-color: var(--yellow-color) !important;
  // color:var(--red-color) !important
  // width:140px
}